<template>
    <div id="copyright">
        <span>© Stocklear 2018-{{ new Date().getFullYear() }} {{ $t('All rights reserved') }}</span>
        <span><NuxtLink target="_blank" :to="$t('url_legal')">{{ $t('Legal Notices') }}</NuxtLink></span>
        <span><NuxtLink target="_blank" :to="$t('url_tos')">{{ $t('Terms') }}</NuxtLink></span>
    </div>
</template>

<style lang="scss" scoped>
    #copyright {
        @include text-md;

        & > span:not(:last-of-type):after {
            content: ' - ';
        }
    }
</style>